import { gql, useMutation } from '@apollo/client';

const mutation = gql`
  mutation OnboardBroker($input: OnboardBrokerInput!) {
    onboardBroker(onboardBrokerInput: $input) {
      email
    }
  }
`

export const useBrokerOnboarding = () => {
  const [execute, { data, fetching, error }] = useMutation(
    mutation,
    { fetchPolicy: 'no-cache' }
  );

  return {
    execute: (data) => execute({ variables: { input: data }}),
    data,
    fetching,
    error
  };
};