import React from 'react';
import './ResumeFooter.scss';
import { ONBOARDING_STEPS } from '../../../utils/constants';
import { OptionDropdown } from '../../atoms/OptionDropdown/OptionDropdown';

export const ResumeFooter = (props) => {
  const { isHidden, isBrokerMode } = props;
  const className = isHidden ? 'resume-footer hidden' : 'resume-footer'

  return (
    <div className={className}>
      <p>Update your loan</p>
      <FooterContent oneLine={true} hideLastStep={true} isBrokerMode={isBrokerMode} {...{ ...props }} />
    </div>
  );
}

export const SpanOption = ({text}) => {
  return (
    <span className='option'>
      {text}
    </span>
  )
}

export const FooterContent = ({ values, setValue, setSelectedIndex, oneLine, hideLastStep, isSpan, isBrokerMode, isBrokerFirst, isBrokerLast, firstPerson, isBrokerResume, isProcessingTeamMode }) => {
  const Wrapper = (
    isSpan
    ?
    (props) => <SpanOption text={getText(values, props.isSet, props.index)} />
    :
    (props) => <OptionDropdown {...props} isBrokerMode={isBrokerMode} values={values} setValue={setValue} setSelectedIndex={setSelectedIndex}/>
  )

  if (!values) {
    return null
  }

  let state = (
    <Wrapper
      isSet={values[ONBOARDING_STEPS[0].text]}
      index={0}
    />
  )

  const loanType = (
    <Wrapper
      isSet={values[ONBOARDING_STEPS[1].text]}
      index={1}
    />
  )

  const loanOwner = (
    <Wrapper
      isSet={values[ONBOARDING_STEPS[3].text]}
      index={3}
    />
  )

  const propertyType = (
    <Wrapper
      isSet={values[ONBOARDING_STEPS[4].text]}
      index={4}
    />
  )

  const ficoRange = (
    <Wrapper
      isSet={values[ONBOARDING_STEPS[5].text]}
      index={5}
    />
  )

  const propertiesNegotiated = (
    <Wrapper
      isSet={values[ONBOARDING_STEPS[6].text]}
      index={6}
    />
  )

  let content = <React.Fragment>
    I want a loan in
  </React.Fragment>;

  if (isBrokerFirst) {
    return (
      <div className='resume-content'>
        <div>
          Their FICO score is {ficoRange} and they’ve purchased/sold {propertiesNegotiated} in the last 24 months
        </div>
      </div>
    )
  } else if (isBrokerLast) {
    return (
      <div className='resume-content'>
        <div>This is a {loanType} for a {propertyType} {loanOwner}</div>
      </div>  
    )
  }

  if (state.props.isSet) {
    if (!isBrokerMode) {
      content = <React.Fragment>
        <div>I want a loan in {state}</div>
      </React.Fragment>
    } else {
      content = <React.Fragment>
        <div>Show me loan terms for a property in {state}</div>
      </React.Fragment>
    }
    if (loanType.props.isSet) {
      if (!isBrokerMode) {
        content = <React.Fragment>
          <div>I want a {loanType} loan in {state}</div>
        </React.Fragment>
      } else {
        content = <React.Fragment>
          <div>Show me {loanType} loan terms for a property in {state}</div>
        </React.Fragment>
      }
      if (loanOwner.props.isSet) {
        if (!isBrokerMode) {
          content = <React.Fragment>
            <div>I want a {loanType} loan for a {loanOwner} in {state}</div>
          </React.Fragment>
        } else {
          content = <React.Fragment>
            <div>Show me {loanType} loan terms for a property {loanOwner} in {state}</div>
          </React.Fragment>
        }
        if (propertyType.props.isSet) {
          if (!isBrokerMode) {
            content = <React.Fragment>
              <div>I want a {loanType} loan for a {propertyType} {loanOwner} in {state}</div>
            </React.Fragment>
          } else {
            content = <React.Fragment>
              <div>Show me {loanType} loan terms for a {propertyType} {loanOwner} in {state}</div>
            </React.Fragment>
          }
          if (ficoRange.props.isSet) {
            if (!isBrokerMode) {
              if (oneLine) {
                content = <React.Fragment>
                  <div>I want a {loanType} loan for a {propertyType} {loanOwner} in {state}.
                    My FICO score is {ficoRange}</div>
                </React.Fragment>
              } else {
                content = <React.Fragment>
                  <div>I want a {loanType} loan for a {propertyType} {loanOwner} in {state}.</div>
                  <div>My FICO score is {ficoRange}</div>
                </React.Fragment>
              }
            } else {
              if (oneLine) {
                content = <React.Fragment>
                  <div>Show me {loanType} loan terms for a {propertyType} {loanOwner} in {state}.
                    The borrower has a FICO score of {ficoRange}</div>
                </React.Fragment>
              } else {
                content = <React.Fragment>
                  <div>Show me {loanType} loan terms for a {propertyType} {loanOwner} in {state}.</div>
                  <div>The borrower has a FICO score of {ficoRange}</div>
                </React.Fragment>
              }
            }
            if (propertiesNegotiated.props.isSet && !hideLastStep) {
              if (!oneLine) {
                if (!isBrokerMode) {
                  content = <React.Fragment>
                    <div>Show me {loanType} loan terms for a {propertyType} {loanOwner} in {state}</div>
                    <div>My FICO score is {ficoRange} and I’ve purchased/sold {propertiesNegotiated} in the past 24 months</div>
                  </React.Fragment>
                } else {
                  content = <React.Fragment>
                    <div>Show me {loanType} loan terms for a {propertyType} {loanOwner} in {state}</div>
                    <div>The borrower has a FICO score of {ficoRange} and has purchased/sold {propertiesNegotiated} in the past 24 months</div>
                  </React.Fragment>
                }

                if (isProcessingTeamMode) {
                  const Wrapper = (props) => <SpanOption text={getText(values, props.isSet, props.index)} />

                  const ficoRange = (
                    <Wrapper
                      isSet={values[ONBOARDING_STEPS[5].text]}
                      index={5}
                    />
                  )
                
                  const propertiesNegotiated = (
                    <Wrapper
                      isSet={values[ONBOARDING_STEPS[6].text]}
                      index={6}
                    />
                  )

                  content = <React.Fragment>
                    <div>Show me {loanType} loan terms for a {propertyType} {loanOwner} in {state}</div>
                    <div>The borrower has a FICO score of {ficoRange} and has purchased/sold {propertiesNegotiated} in the past 24 months</div>
                  </React.Fragment>
                }

              } else {
                if (isBrokerMode) {
                  if (isBrokerResume) {
                    content = <React.Fragment>
                      <div>My borrower wants a {loanType} loan for a {propertyType} {loanOwner}.
                        They have a FICO score of {ficoRange} and have purchased/sold {propertiesNegotiated} in the last 24 months</div>
                    </React.Fragment>
                  } else {
                    content = <React.Fragment>
                      <div>Show me {loanType} loan terms for a {propertyType} {loanOwner} in {state}.
                        The borrower has a FICO score of {ficoRange} and has purchased/sold {propertiesNegotiated} in the past 24 months</div>
                    </React.Fragment>
                  }
                } else {
                  if (firstPerson) {
                    content = <React.Fragment>
                      <div>I want a {loanType} loan for a {propertyType} {loanOwner} in {state}.
                        My FICO score is {ficoRange} and I’ve purchased/sold {propertiesNegotiated} in the past 24 months</div>
                    </React.Fragment>
                  } else {
                    content = <React.Fragment>
                      <div>Show me {loanType} loan terms for a {propertyType} {loanOwner} in {state}.
                        My FICO score is {ficoRange} and I’ve purchased/sold {propertiesNegotiated} in the past 24 months</div>
                    </React.Fragment>
                  }
                }

                if (isProcessingTeamMode) {
                  content = <React.Fragment>
                    <div>The borrower wants a {loanType} loan for a {propertyType} {loanOwner} in {state}.
                    Their FICO score is {ficoRange} and they've purchased/sold {propertiesNegotiated} in the past 24 months</div>
                  </React.Fragment>
                }
              }
            }
          }
        }
      }
    }
  }

  return (
    <div className='resume-content'>
      {content}
    </div>
  );
}

const mixedUsePropertyNotice = "We can’t automatically price mixed-use bridge loans. But if you finish the remaining questions, one of our loan officers will be in touch"
export function getText(values, option, index) {
  let text = null

  switch(index) {
    case 0:
      text = option
      break;
    case 1:
      const isBridge = (
        values[ONBOARDING_STEPS[1].text] === ONBOARDING_STEPS[1].options[0].text
        &&
        values[ONBOARDING_STEPS[2].text] === ONBOARDING_STEPS[2].options[0].text
      )
      const isRental = (
        values[ONBOARDING_STEPS[1].text] === ONBOARDING_STEPS[1].options[0].text
        &&
        values[ONBOARDING_STEPS[2].text] === ONBOARDING_STEPS[2].options[1].text
      )
      const isFix = (
        values[ONBOARDING_STEPS[1].text] === ONBOARDING_STEPS[1].options[1].text
        &&
        values[ONBOARDING_STEPS[2].text] === ONBOARDING_STEPS[2].options[0].text
      )

      if (isBridge) {
        text = "12-month bridge"
      } else if (isRental) {
        text = "30-year rental"
      } else if (isFix) {
        text = "12-month fix-and-flip"
      } else {
        text = "bridge or rental"
      }
      break;
    case 2:
      text = (
        [
          "12-month",
          "30-year"
        ][ONBOARDING_STEPS[2].options.findIndex(({ text }) => text === option)]
      )
      break;
    case 3:
      text = (
        [
          "purchase",
          "refinance"
        ][ONBOARDING_STEPS[3].options.findIndex(({ text }) => text === option)]
      )
      break;
    case 4:
      text = (
        [
          "single-family home",
          "2-4 unit property",
          "portfolio",
          "warrantable condo",
          "non-warrantable condo",
          "5-20 unit property",
          "mixed-use property",
        ][ONBOARDING_STEPS[4].options.findIndex(({ text }) => text === option)]
      )
      break;
    case 5:
      text = (
        [
          "less than 660",
          "660-679",
          "680-699",
          "700-719",
          "720-739",
          "740-759",
          "760-779",
          "780+",
        ][ONBOARDING_STEPS[5].options.findIndex(({ text }) => text === option)]
      )
      break;
    case 6:
      text = (
        [
          "0 properties",
          "1-2 properties",
          "3-5 properties",
          "6+ properties"
        ][ONBOARDING_STEPS[6].options.findIndex(({ text }) => text === option)]
      )
      break;
  }

  return text
}