import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import './Resume.scss';

import { Icon } from '../../atoms/Icon/Icon';
import { FooterContent } from '../ResumeFooter/ResumeFooter';
import { OptionDropdownInner } from '../../atoms/OptionDropdown/OptionDropdown';
import { useGetBorrowers } from '../../../services/borrowers';

export const Resume = (props) => {
  const { isSelected, setBottomValue, setSelectedIndex, values, setValue, onFinish, isBrokerMode, setBorrowerData } = props;
  const { isProcessingTeamMode, isBorrowerMode } = props;

  const stepRef = useRef()

  useEffect(() => {
    if (stepRef.current && isSelected) {
      setBottomValue(stepRef.current.offsetTop)
    }
  }, [isSelected, stepRef.current])

  const Wrapper = useMemo(() => isProcessingTeamMode || isBorrowerMode ? (props) => <div  className='processing-card' {...props} />  : React.Fragment, [isProcessingTeamMode, isBorrowerMode])

  return (
    <div className='step-section resume processing' ref={stepRef}>
      <Wrapper>
        <div className='icon-container'>
          <Icon svgFilePath={"./assets/icons/icon-coins.svg"} isBrokerMode={isBrokerMode} />
        </div>
        {
          isProcessingTeamMode ?
            <BorrowerSelectionSection setBorrowerData={setBorrowerData} />
            :
            null
        }
        <FooterContent values={values} setSelectedIndex={setSelectedIndex} setValue={setValue} isBrokerMode={isBrokerMode} isProcessingTeam={isProcessingTeamMode}/>
        <div className='loan-options-button' onClick={onFinish}>
          {
            !isProcessingTeamMode ?
              "Show me my loan options"
              :
              "Show me loan options for this borrower"
          }
        </div>
      </Wrapper>
    </div>
  );
}

const BorrowerSelectionSection = ({ setBorrowerData }) => {
  const { data } = useGetBorrowers()

  const [selectedBorrowerEmail, setSelectedBorrowerEmail] = useState(null)
  const [filterValue, setFilterValue] = React.useState(null)

  const borrowers = data?.getBorrowers?.filter((r) => r.name) || []

  let options = [
    ...borrowers
      .map((v) => {
        return {
          value: v.email,
          label: `${v.name} ${v.lastName}`,
          disabled: false,
          className: undefined,
          raw: v
        }
      })
  ]

  const defaultValue = options[0]

  const isUpdated = useRef(false)
  useEffect(() => {
    if (defaultValue && !isUpdated.current) {
      setBorrowerData(defaultValue.raw)
      isUpdated.current = true
    }
  }, [defaultValue])

  if (filterValue) {
    options = options.filter((o) => o.label.toLowerCase().includes(filterValue.toLowerCase()))
  }

  options.push(
    {
      value: "new",
      label: "New Borrower",
      disabled: false,
      className: 'new-borrower',
      raw: null
    }
  )

  if (!borrowers.length) {
    return null
  }

  const onChange = (v) => {
    setSelectedBorrowerEmail(v)
    setBorrowerData(options.find((o) => o.value === v).raw)
  }

  return (
    <span>
      New loan for {" "}
      <div style={{ display: 'inline-block' }}>
        <OptionDropdownInner
          isProcessingTeamMode={true}
          onSearch={(s) => setFilterValue(s)}
          options={options}
          defaultValue={defaultValue}
          value={selectedBorrowerEmail || options[0]}
          onChange={onChange}
        />
      </div>
    </span>
  )
}
