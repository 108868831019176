import React, { useState } from 'react';
import { ONBOARDING_STEPS } from '../../../utils/constants';
import './BubbleNavigator.scss';

export const DummyBubbleNavigator = (props) => {
  const { quantity = 5 } = props;

  const [selectedIndex, setSelectedIndex] = useState(0)

  return <BubbleNavigator {...{ selectedIndex, setSelectedIndex, quantity}}/>
}

export const BubbleNavigator = (props) => {
  const { selectedIndex, setSelectedIndex, quantity, values } = props;

  return (
    <div className='bubble-container'>
      {
        Array
          .from(Array(quantity).keys())
          .map(
            (i) => (
              <Bubble
                key={`${i}_{selectedIndex}`}
                isSelected={i === selectedIndex}
                onClick={() => setSelectedIndex(i)} 
                isDisabled={Object.keys(values).length < i}
              />
            )
          ) 
      }
      <div className='arrow'>
        <img src={`${process.env.PUBLIC_URL}/assets/images/blue-down-arrow.png`} alt='arrow'/>
      </div>
      <div className='calc'>
        <img src={`${process.env.PUBLIC_URL}/assets/images/blue-calculator-icon.png`} alt='calc'/>
      </div>
    </div>
  );
}

const Bubble = (props) => {
  const { isSelected, onClick, isDisabled } = props;

  let className = 'bubble';
  if (isSelected) {
    className += ' selected';
  }
  if (isDisabled) {
    className += ' disabled';
  }

  return (
    <div {...{ className, onClick }}></div>
  )
}

export const BubbleNavigatorContainer = ({ children }) => (
  <div className='bubble-navigator-container'>
    {children}
  </div>
) 