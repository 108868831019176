import React, { useEffect, useState, useRef } from 'react';
import './NumberInput.scss';
import { InputNumber } from 'antd';

export const NumberInput = (props) => {
  const { label, value, setValue = () => {}, defaultValue = 0, error, isDisabled } = props;
  let [ innerValue, setInnerValue ] = useState(value || undefined);

  const [currentError, setCurrentError] = useState(error);

  const inputFocused = useRef(false);
  const [ blurred, setBlurred ] = useState(false);

  const showError = Boolean(false);

  const className = `${!inputFocused.current ? 'loan-input placeholder' : 'loan-input'} ${showError ? 'error' : ''}`;

  useEffect(() => {
    setCurrentError(error);
  }, [blurred])

  useEffect(() => {
    if (currentError !== error && blurred) {
      setCurrentError(error);
    }
  }, [error, blurred])

  useEffect(() => {
    if (inputFocused.current) {
      setValue(innerValue)
    }
  }, [innerValue])

  return (
    <div className={className}>
      <p>{label}</p>
      <InputNumber
        addonBefore={"$"}
        bordered={false}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        value={innerValue === undefined ? defaultValue : (innerValue === null ? 0 : innerValue)}
        onChange={setInnerValue}
        onFocus={() => {
          if (!inputFocused.current) {
            // setInnerValue("");
            inputFocused.current = true;
          }
          // setBlurred(false)
        }}
        onBlur={() => setBlurred(true)} // Add this line
        disabled={isDisabled}
      />
      {showError && <p className='error-message'>{currentError}</p>}
    </div>
  )
}

/* Utils */
const locale = "en-us";

export const currencyFormatter = (value, digits = 2) => {
  const r = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: digits
  }).format(value);

  return r.substring(1)
};

export const currencyParser = val => {
  val = '$'.concat(val)

  try {
    // for when the input gets clears
    if (typeof val === "string" && !val.length) {
      val = "0.0";
    }

    // detecting and parsing between comma and dot
    var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
    var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
    var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
    reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, "");
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;

    if (needsDigitsAppended) {
      reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    console.error(error);
  }
};