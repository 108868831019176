import React from 'react';
import './Icon.scss';

export const Icon = (props) => {
  let { svgFilePath, isAlt, isBrokerMode } = props;

  const [_, fileName, extension ] = svgFilePath.split(".")

  if (isBrokerMode) {
    svgFilePath = `.${fileName}-blue.${extension}`;
  }

  if (isAlt) {
    svgFilePath = `.${fileName}-alt.${extension}`;
  }

  return (
    <img className='icon' src={`${process.env.PUBLIC_URL}${svgFilePath.split(".")[1]}.${extension}`} alt="icon" />
  );
}