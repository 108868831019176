import React, { useState } from 'react';
import './CreateAccountBroker.scss';

import { StepBarContent } from '../StepBar/StepBar';
import { FooterContent } from '../ResumeFooter/ResumeFooter';
import { OptionDropdownInner } from '../../atoms/OptionDropdown/OptionDropdown';
import { TextInput } from '../../atoms/TextInput/TextInput';
import { AddressCaption } from '../PreApprovalForm/PreApprovalForm';

import { formatPhoneNumber } from '../PreApprovalForm/PreApprovalForm';

const options = [
  {
    value: "client",
    label: "My client",
    disabled: false,
    className: undefined
  }
]

export const CreateAccountBroker = (props) => {
  /* Props */
  const { onSubmission, values } = props;

  /* User Data */
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [password, setPassword] = useState('password')
  const [passwordVerification, setPasswordVerification] = useState('password')

  const [borrowerFirstName, setBorrowerFirstName] = useState("")
  const [borrowerLastName, setBorrowerLastName] = useState("")
  const [investmentAddress, setInvestmentAddress] = useState("")

  const createOnChange = (fun) => (e) => fun(e.target.value);

  /* State - Step */
  const [step, setStep] = useState(0)

  const firstStepCompleted = true && firstName && lastName && email && phoneNumber && password && passwordVerification

  const setSelectedStep = (newStep) => {
    if (step === 0 && !firstStepCompleted) {
      return
    } else {
      setStep(newStep)
    }
  }

  const nextStep = () => {
    setSelectedStep(step + 1)
  }

  /* Markup */
  const firstStepOnClick = () => {
    nextStep()
  }

  const stepBarMarkup = (
    <StepBarContent
      selectedStep={step}
      setSelectedStep={setSelectedStep}
      options={["Broker Information", "Borrower Information"]}
    />
  )

  const [errors, setErrors] = useState({});

  const firstStepMarkup = (
    <React.Fragment>
      <div className='inner-container'>
        { stepBarMarkup }    
        <h1 className='title'>
          Enter your info to offer loans to your real estate investor clients
        </h1>

        <div className='wide-input-container'>
          <TextInput label={"First name"} value={firstName} onChange={(e) => { console.log(e); setFirstName(e) }} error={errors.firstName} />
          <TextInput label={"Last name"} value={lastName} onChange={(e) => setLastName(e)} error={errors.lastName} />
          <TextInput label={"Email address"} value={email} onChange={(e) => setEmail(e)} error={errors.email} />
          <TextInput label={"Phone number"} value={phoneNumber} onChange={(e) => setPhoneNumber(formatPhoneNumber(e))} error={errors.phoneNumber} />
        </div>
      </div>

      <div className='resume-container'>
        <FooterContent values={values} oneLine={true} isSpan={true} isBrokerMode={true} isBrokerResume={true} />
      </div>

      <div className='loan-options-button' onClick={firstStepOnClick}>
        Go to borrower info
      </div>
      <p className='caption'>
        By submitting this form you agree to be contacted by Constitution Lending
      </p>
    </React.Fragment>
  )

  const secondStepOnClick = () => {
    const userData = {
      broker: {
        firstName, lastName, email, phoneNumber, password
      },
      borrower: {
        firstName: borrowerFirstName,
        lastName: borrowerLastName,
        investmentAddress: investmentAddress
      }
    }
    onSubmission(userData)
  }

  const secondStepMarkup = (
    <React.Fragment>
      <div className='padding-container'>
        { stepBarMarkup }
        <h1 className='title'>
          Enter borrower info to get a term sheet and pre-approval letter
        </h1>

        <p className='description'>
          <OptionDropdownInner
            defaultValue={options[0]}
            options={options}
            customWidth={"200px"}
            isBrokerMode={true}
          /> is the borrower
        </p>

        <div className='input-container'>
          <TextInput label='First Name' value={borrowerFirstName} onChange={(e) => setBorrowerFirstName(e)} />
          <TextInput label='Last Name' value={borrowerLastName} onChange={(e) => setBorrowerLastName(e)} />
        </div>
      </div>

      <div className='resume-container first'>
        <FooterContent values={values} oneLine={true} isSpan={true} isBrokerFirst={true} />
      </div>

      <div className='padding-container'>
        <TextInput className="investment" label='Investment Property Address' value={investmentAddress} onChange={(e) => setInvestmentAddress(e)}/>
        <AddressCaption />
      </div>

      <div className='resume-container last'>
        <FooterContent values={values} oneLine={true} isSpan={true} isBrokerLast={true} />
      </div>

      <div className='loan-options-button' onClick={secondStepOnClick}>
        Get a term sheet for your client
      </div>
      <p className='caption'>
        By submitting this form you agree to be contacted by Constitution Lending
      </p>
    </React.Fragment>
  )

  /* Component */
  return (
    <div className='create-account-broker broker'>
      <div className='info'>
        {
          step === 0
            ?
            firstStepMarkup
            :
            secondStepMarkup
        }
      </div>
    </div>
  );
}
