import React, { useEffect, useState } from 'react';
import { Tooltip, ConfigProvider } from 'antd';
import { makeVar, useReactiveVar } from '@apollo/client';

const TooltipSVG = ({ variant }) => {
  const GRAY_COLOR = "#6E7889"
  const BLUE_COLOR = "#5E8EB7"
  const PURPLE_COLOR = "#8A5791"

  let color;
  switch (variant) {
    case "gray":
      color = GRAY_COLOR;
      break;
    case "purple":
      color = PURPLE_COLOR;
      break;
    default:
      color = BLUE_COLOR;
  }

  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <g clip-path="url(#clip0_623_691)">
      <path d="M6.99984 12.8333C10.2215 12.8333 12.8332 10.2216 12.8332 6.99996C12.8332 3.7783 10.2215 1.16663 6.99984 1.16663C3.77818 1.16663 1.1665 3.7783 1.1665 6.99996C1.1665 10.2216 3.77818 12.8333 6.99984 12.8333Z" stroke={color} stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7 4.66663V6.99996" stroke={color} stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7 9.33337H7.01" stroke={color} stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_623_691">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
}

const TooltipCloseSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <g clip-path="url(#clip0_623_1035)">
      <path d="M7.00033 12.8333C10.222 12.8333 12.8337 10.2216 12.8337 6.99996C12.8337 3.7783 10.222 1.16663 7.00033 1.16663C3.77866 1.16663 1.16699 3.7783 1.16699 6.99996C1.16699 10.2216 3.77866 12.8333 7.00033 12.8333Z" stroke="#AC3E3A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9 5L5 9.01045" stroke="#AC3E3A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5 5L9 9.01045" stroke="#AC3E3A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_623_1035">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
)


const StyledTooltip = ({ title, placement, variant, children, open }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tooltip: {
            colorBgSpotlight: "#355C7D",
            colorTextLightSolid: "#F5FAFF",
            fontFamily: "Inter",
            fontSize: 10,
          }
        },
      }}
    >
      <Tooltip
        title={title}
        placement={placement}
        open={open}
        overlayClassName={`info ${variant}`}
      >
        {children}
      </Tooltip>
    </ConfigProvider>
  );
};

const shouldCloseTooltipVar = makeVar(false)

export const InfoTooltip = ({ children, variant, isDisabled, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleTooltip = (event) => {
    if (isDisabled) return;
    
    event.stopPropagation();
    if (!isOpen) {
      shouldCloseTooltipVar(true)
      setTimeout(() => setIsOpen(true), 100)
    } else {
      setIsOpen(false)
    }
  };

  const shouldCloseTooltip = useReactiveVar(shouldCloseTooltipVar)

  useEffect(() => {
    if (shouldCloseTooltip) {
      setIsOpen(false)
      shouldCloseTooltipVar(false)
    }
  }, [shouldCloseTooltip])

  return (
    <StyledTooltip {...props} open={isOpen}>
      <span className={`tooltip-svg ${variant} ${isOpen ? "closeable" : ""}`} onClick={toggleTooltip}>
        {
          !isOpen ?
            <TooltipSVG variant={variant} />
            :
            <TooltipCloseSVG />
        }
      </span>
    </StyledTooltip>
  );
};

export const InfoTooltipPositioner = ({ children }) => (
  <span className='tooltip'>
    {children}
  </span>
)