import React, { useEffect, useRef } from 'react';
import './StepSection.scss';

import { Icon } from '../../atoms/Icon/Icon';
import { SelectOption } from '../../atoms/SelectOption/SelectOption';
import { Select } from 'antd';
import { trackEvent, QuestionFocused } from '../../../utils/mixpanel';

export const StepSection = (props) => {
  const { step, isSelected, value, onSelect, setBottomValue, isBrokerMode, customMarkup } = props;
  const { iconPath, text, description, options } = step;

  const stepRef = useRef()

  useEffect(() => {
    if (stepRef.current && isSelected) {
      setBottomValue(stepRef.current.offsetTop + 40)
    }
  }, [isSelected, stepRef.current])

  useEffect(() => {
    if (isSelected) {
      trackEvent(QuestionFocused(text))
    }
  }, [isSelected])

  const optionsMarkup = (
    step.isSelect
    ?
    <Select
      popupClassName={isBrokerMode ? 'broker' : undefined}
      value={value}
      placeholder={step.selectTitle}
      options={
        options.map(({ text, isDisabled }) => ({ value: text, label: text, disabled: isDisabled }))
      }
      onChange={(value) => { onSelect(value) }}
    />
    :
    options.map((option) => (
      <SelectOption key={option.text} {...option} onSelect={() => onSelect(option.text)} isSelected={option.text === value}/>
    ))
  )

  return (
    <div
      className='step-section'
      ref={stepRef}
    >
      <div className='icon-container'>
        <Icon svgFilePath={iconPath} isAlt={(!isSelected)} isBrokerMode={isBrokerMode} />
      </div>
      <div className='info'>
        <h1 className='title'>{text}</h1>
        {
          description
          ?
          <h3 className='description'>{description}</h3>
          :
          null
        }
      </div>
      <div className='options'>
        {
          optionsMarkup
        }
        {
          customMarkup
        }
      </div>
    </div>
  );
}