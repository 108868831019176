import { TermSheet } from "./components/molecules/LoanResume/utils";

const BRIDGE_INPUT = {
  address: "123 Main St",
  propertyType: "Single Family",
  creditScore: 700,
  investmentExperience: "3-5",
  price: 500000,
  currentLoanBalance: 0,
  cashoutAmount: 0,
  ltv: 0.75,
  rate: 0.05,
  totalLoanAmount: 375000,
  buydownPoints: 1
};

const BRIDGE_REFINANCE_INPUT = {
  address: "123 Main St",
  propertyType: "Single Family",
  creditScore: 700,
  investmentExperience: "3-5",
  price: 500000,
  totalLoanAmount: 375000,
  currentLoanBalance: 400000,
  cashoutAmount: 0,
  ltv: 0.75,
  rate: 0.05,
  buydownPoints: 1
};

const FIX_AND_FLIP_INPUT = {
  address: "456 Elm St",
  propertyType: "Commercial",
  creditScore: 650,
  investmentExperience: "Novice",
  price: 300000,
  currentLoanBalance: 240000,
  cashoutAmount: 50000,
  rehabBudget: 50000,
  arv: 400000,
  ltv: 0.7,
  ltc: 0.75,
  rate: 0.06,
  totalLoanAmount: 210000,
  buydownPoints: 1
};

export function runTests() {
    let input = FIX_AND_FLIP_INPUT
    
    let sheet = TermSheet.constructForFixAndFlipLoan(input);
    
    let data = {
      /* Values */
      address: sheet.address,
      loanType: sheet._loanType,
      asIsValue: sheet.asIsValue,

      /* Options */
      loanAmount: sheet._totalLoanAmount,
      payoff: sheet._currentLoanBalance,
      buydownPoints: sheet._buydownPoints,

      /* Closing Cash */
      closingCashAmount: sheet.closingCashAmount,
      closingCash: sheet.closingCash,
      originationFee: sheet.originationFee,
      serviceFeeOrProcessingFee: sheet.serviceFee,
      proRatedInterestOrStubInterest: sheet.proRatedInterest,
      downPayment: sheet.downPayment,
      thirdPartyCosts: sheet.thirdPartyCosts,
      legalCosts: sheet.legalCosts,
      propertyTaxesAndInsuranceReserves: sheet.rentalFee,
      pointsCharged: sheet._buydownPoints,

      /* Additional Details */
      amortization: sheet.amortization,
      advanceAmount: sheet.advanceAmount,
      arv: sheet.estimatedARV,
      constructionReserve: sheet.constructionReserve,
      lien: sheet.lien,
    }

    Object.keys(data).forEach(key => {
      console.log(`${key}: ${data[key]}`);
    });


}