import React, { useEffect, useState } from 'react';
import './LoanResume.scss';

import { FooterContent } from '../ResumeFooter/ResumeFooter';
import { LoanOption } from '../../atoms/LoanOption/LoanOption';
import { ONBOARDING_STEP_CREDIT_SCORE_KEY, ONBOARDING_STEP_INVESTMENT_EXPERIENCE_KEY, ONBOARDING_STEP_PROPERTY_KIND_KEY, getOnboardingStepTitle, toUSD } from '../../../utils/constants';
import { Icon } from '../../atoms/Icon/Icon';
import { getInputData } from '../LoanBuilder/LoanBuilder';
import { useBorrowerOnboarding } from '../../../services/onboarding'
import { useBrokerOnboarding } from '../../../services/onboarding-broker'
import { Button } from '../PreApprovalForm/PreApprovalForm';
import { isLongTermRental, isRefinance, isShortTermBridge, isShortTermFixAndFlip } from '../../../utils/functions';
import { TermSheet } from './utils';
import { trackEvent, UserOnboarded, trackSectionLoadedEvent } from '../../../utils/mixpanel';
import ReactGA from "react-ga4";

const DEFAULT_SELECTED_OPTION = {
  ltv: 75,
  loanTerm: "12 months",
  rate: 9.45,
  monthlyPayment: 1181.25,
  totalLoanAmount: 175000,
  caption: 'BEST LTV'
}

const DEFAULT_LOAN_DATA = {
  purchaseValue: 150000,
  cashRequired: 3334.83,
  originationFee: 2.34,
  serviceFee: 999.00,
  proRatedInterest: 300.00,
  thirdPartyCosts: "Contact closing agent",
  pointsCharged: 1,
  additionalDetails: {
    loanType: "1 year fixed",
    amortization: "Interest Only",
    lien: "1st position",
    asIsValue: 200000.00,
    estimatedArv: 300000.00,
    holdbackAmount: 25000
  },
  documents: {
    preApprovalLetterURL: "https://storage.googleapis.com/constlending-borrowers/1704920475430.pdf",
    termSheetURL: "https://storage.googleapis.com/constlending-borrowers/1704920475430.pdf"
  }
}

window.send_convertion_event = () => {
  if (window.gtag && window.location.hostname === 'borrow.constlending.com') {
    try {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-611686651/FZOgCPPV1dgZEPux1qMC',
        'event_callback': () => {}
      });
    } catch(e) {

    }
  }
}

export const fetchDocumentsData = async (termSheetData, userData) => {
  console.log({termSheetData, userData})
  const body = {
    "termSheetData": {
      "asIsValue": termSheetData.asIsValue,
      "rehabBudget": termSheetData.constructionReserve,
      "arv": termSheetData.estimatedARV,
      "creditScore": termSheetData.creditScore,
      "investmentExperience": termSheetData.investmentExperience,
      "propertyType": termSheetData.propertyType,
      "loanPurpose": termSheetData.loanPurpose,
      "loanAmount": termSheetData.totalLoanAmount,
      "advanceAmount": termSheetData.advanceAmount,
      "ltv": termSheetData.ltv,
      "constructionReserve": termSheetData.constructionReserve,
      "ltc": termSheetData.ltc,
      "address": termSheetData.address || "1234 Main St",
      "rate": termSheetData.interestRate,
      "term": termSheetData.term,
      "buydown": termSheetData._buydownPoints,
      "dscr": termSheetData.dscr,
      "monthlyRent": termSheetData.stimatedMonthlyRent,
      "annualExpenses": termSheetData.annualExpenses
    },
    "preApprovalLetterData": {
      "firstName": userData.firstName || userData.borrower?.firstName || "Jhon",
      "lastName": userData.lastName || userData.borrower?.lastName || "Doe",
      "address": termSheetData.address || "1234 Main St",
      "ltv": termSheetData.ltv,
    }
  }

  const res = await fetch('https://sbo.constlending.com/files/generateFiles', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })

  const data = await res.json()

  return data
}

const InputPlaceholder = ({label, customKey, values}) => {
  return (
    <div className='value-item'>
      <p>{label}</p>
      <p>{toUSD(values[customKey], 0)}</p>
    </div>
  )
}

export const LoanResume = (props) => {
  const { values, selectedOption, userData, onBack, isBrokerMode } = props;

  const { execute, data: dataBorrower, fetching: fetchingBorrower, error: errorBorrower } = useBorrowerOnboarding()

  const { execute: executeBroker, data: dataBroker, fetching: fetchingBroker, error: errorBroker } = useBrokerOnboarding()

  const fetching = fetchingBorrower || fetchingBroker
  
  const [userOAuthToken, setUserOAuthToken] = useState(false)

  useEffect(() => {
    trackSectionLoadedEvent('Loan Resume')
  }, [])

  const onSubmission = async (documentsData) => {
    if (userOAuthToken) {
      return
    }

    const loanData = {
      address: userData.investmentAddress || userData.borrower.investmentAddress,
      ...values,
      selectedOption,
      investmentExperience: values["How many properties have you purchased/sold in the last 24 months?"].split(" ")[0].toLowerCase(),
      ...documentsData
    }

    let inputData;

    if (!isBrokerMode) {
      const userAccountData = {
        name: `${userData.firstName.trim()} ${userData.lastName.trim()}`,
        phone: userData.phoneNumber,
        email: userData.email,
        password: userData.password
      }

      inputData = {
        ...userAccountData,
        loanData
      }
    } else {
      const userAccountData = {
        name: `${userData.broker.firstName.trim()} ${userData.broker.lastName.trim()}`,
        phone: userData.broker.phoneNumber,
        email: userData.broker.email,
        password: userData.broker.password
      }

      const borrowerData = {
        name: `${userData.borrower.firstName.trim()} ${userData.borrower.lastName.trim()}`,
        email: "",
        phone: "",
        password: "",
        loanData
      }

      inputData = {
        ...userAccountData,
        borrowerData
      }
    }

    if (ReactGA.isInitialized) {
      ReactGA.set({ userId: inputData.email });
      
      ReactGA.event('borrower_lead', {
        category: 'onboarding',
        action: 'User completed the onboarding process'
      });

      ReactGA.event('sign_up', {
        category: 'onboarding',
        action: 'User completed the onboarding process'
      });
    }
    
    if (window.send_convertion_event) {
      window.send_convertion_event()
    }
  
    let response;

    try {
      if (!isBrokerMode) {
        response = await execute(inputData)
      } else {
        response = await executeBroker(inputData)
      }
    } catch (e) {

    } finally {
      if (!isBrokerMode) {
        setUserOAuthToken(response.data.onboardBorrower)
      } else {
        setUserOAuthToken(response.data.onboardBroker)
      }
      trackEvent(UserOnboarded())
    }
  }

  const [documentsURLs, setDocumentsURLs] = useState({})

  const {
    originationPoints,
    totalLoanAmount
  } = selectedOption || {}

  const {
    price: purchaseValue
  } = values

  const inputData = getInputData(values)

  const elements = inputData.map((v) => <InputPlaceholder {...v} values={values} />)

  useEffect(() => {
    const fun = async () => {
      if (!userOAuthToken && userData) {
        const { data: documentsData } = await fetchDocumentsData(termSheetData, userData)
        setDocumentsURLs(documentsData)
        await onSubmission(documentsData)
      }
    }

    fun()
    
    return () => {}
  }, [userData])

  let termSheetData = null

  const address = userData?.investmentAddress || userData?.borrower?.investmentAddress

  if (isShortTermBridge(values)) {
    termSheetData = TermSheet.constructForBridgeLoan({
      address,
      propertyType: values[getOnboardingStepTitle(ONBOARDING_STEP_PROPERTY_KIND_KEY)],
      creditScore: values[getOnboardingStepTitle(ONBOARDING_STEP_CREDIT_SCORE_KEY)],
      investmentExperience: values[getOnboardingStepTitle(ONBOARDING_STEP_INVESTMENT_EXPERIENCE_KEY)],
      price: values.price,
      currentLoanBalance: values.currentLoanBalance,
      cashoutAmount: selectedOption.cashoutAmount,
      ltv: selectedOption.ltv,
      rate: selectedOption.rate,
      totalLoanAmount: selectedOption.totalLoanAmount,
      buydownPoints: selectedOption.buydownPoints
    })
  } else if (isShortTermFixAndFlip(values)) {
    termSheetData = TermSheet.constructForFixAndFlipLoan({
      address,
      propertyType: values[getOnboardingStepTitle(ONBOARDING_STEP_PROPERTY_KIND_KEY)],
      creditScore: values[getOnboardingStepTitle(ONBOARDING_STEP_CREDIT_SCORE_KEY)],
      investmentExperience: values[getOnboardingStepTitle(ONBOARDING_STEP_INVESTMENT_EXPERIENCE_KEY)],
      price: values.price,
      currentLoanBalance: values.currentLoanBalance,
      cashoutAmount: selectedOption.cashoutAmount,
      rehabBudget: values.constructionBudget,
      arv: values.afterRepairValue,
      ltv: selectedOption.ltv,
      ltc: selectedOption.ltc,
      rate: selectedOption.rate,
      totalLoanAmount: selectedOption.totalLoanAmount,
      buydownPoints: selectedOption.buydownPoints
    })
  } else if (isLongTermRental(values)) {
    termSheetData = TermSheet.constructForRentalLoan({
      address,
      propertyType: values[getOnboardingStepTitle(ONBOARDING_STEP_PROPERTY_KIND_KEY)],
      creditScore: values[getOnboardingStepTitle(ONBOARDING_STEP_CREDIT_SCORE_KEY)],
      price: values.price,
      currentLoanBalance: values.currentLoanBalance,
      cashoutAmount: selectedOption.cashoutAmount,
      monthlyRent: values.monthlyRent,
      annualTaxes: values.annualTaxes,
      annualInsurance: values.annualInsurance,
      annualHOA: values.annualHoa,
      dscr: selectedOption.dscr,
      ltv: selectedOption.ltv,
      rate: selectedOption.rate,
      totalLoanAmount: selectedOption.totalLoanAmount,
      buydownPoints: selectedOption.buydownPoints
    })
  }

  const onContinue = () => {
    if (userOAuthToken) {
      window.location.href = `https://sbd.constlending.com/?oauth=${userOAuthToken}&isNew=true`
    }
  }

  const preApprovalLetterURL = documentsURLs?.preApprovalLetterURL
  const termSheetURL = documentsURLs?.termSheetURL
  const mobileURL = documentsURLs?.mobileURL

  const firstName = userData?.firstName || userData?.broker?.firstName || "Ricardo"
  
  return (
    <React.Fragment>
      <div className='loan-recap'>
        <div className='left-column'>
          <h1>{firstName}, your loan application has been submitted!</h1>
          <div className='resume-container'>
            {/* <p>Update your loan</p> */}
            <FooterContent values={values} oneLine={true} isSpan={true} firstPerson isBrokerMode={isBrokerMode} />
          </div>
          {elements}
        </div>
        <div className='right-column'>
          {/* {
            userOAuthToken ?
              <ConfirmationBox isBrokerMode={isBrokerMode} />
              :
              null
          } */}
          <DocumentsBox
            preApprovalLetterURL={preApprovalLetterURL}
            termSheetURL={termSheetURL}
          />
          <LoanInfoBox
            selectedOption={selectedOption}
            values={values}
            termSheetData={termSheetData || {}}
          />
          <Button onClick={onContinue} isLoading={!userOAuthToken}>
            Continue Application
          </Button>
        </div>
      </div>
      <LoanResumeMobile
        firstName={firstName}
        mobileURL={mobileURL}
        selectedOption={selectedOption}
        values={values}
        termSheetData={termSheetData || {}}
        isBrokerMode={isBrokerMode}
        elements={elements}
      />
    </React.Fragment>
  );
}

const LoanInfoBox = ({
  selectedOption,
  values,
  termSheetData
}) => {

  const {
    closingCashAmount,
    closingCash: cashRequired,
    originationFee,
    originationFeePercentage,
    serviceFee,
    proRatedInterest,
    buydownFee,
    buydowFeePercentage,
    downPayment,
    _buydownPoints: originationPoints,
    totalLoanAmount,
    purchaseValue,
    rentalFee,
    rentalFeeAmount,
    advanceAmount,
    thirdPartyCosts,
    legalCosts,
    loanType,
    amortization,
    lien,
    asIsValue,
    estimatedARV,
    constructionReserve,
    dscr
  } = termSheetData

  return (
    <div className='loan-info'>
      <LoanOption {...selectedOption} hideChoose={true} isResume={true} />
      <div className='details'>
        <div>
          <div>
            <div>
              {
                closingCashAmount < 0 ?
                  "Est. Cash To Be Received At Closing"
                  :
                  "Est. Cash Required At Closing"
              }
            </div>
            <span>
              {cashRequired}
            </span>
          </div>
          <div>
            {
              !isRefinance(values) ?
                <div>
                  <p>
                    Down Payment
                  </p>
                  <span>
                    {downPayment}
                  </span>
                </div>
                :
                null
            }
            <div>
              <p>
                Origination Fee
              </p>
              <span>
                {`${originationFee} (${originationFeePercentage})`}
              </span>
            </div>
            <div>
              <p>
                Buydown Fee
              </p>
              <span>
                {`${buydownFee} (${originationPoints}%)`}
              </span>
            </div>
            <div>
              <p>
                Processing Fee
              </p>
              <span>
                {serviceFee}
              </span>
            </div>
            {
              isLongTermRental(values) ?
                <div>
                  <p>
                    Property Taxes and Insurance Reserves
                  </p>
                  <span>
                    {rentalFee}
                  </span>
                </div>
                :
                null
            }
            <div>
              <p>
                Third-party Costs
              </p>
              <span>
                {thirdPartyCosts}
              </span>
            </div>
            <div>
              <p>
                Est. Legal Costs
              </p>
              <span>
                {legalCosts}
              </span>
            </div>
          </div>
        </div>
        <div>
          <div>
            Additional Details
          </div>
          <div>
            <div>
              <p>Loan Type</p>
              <span>{loanType}</span>
            </div>
            <div>
              <p>Amortization</p>
              <span>{amortization}</span>
            </div>
            {
              isShortTermFixAndFlip(values) ?
                <div>
                  <p>Advance Amount</p>
                  <span>{advanceAmount}</span>
                </div>
                :
                null
            }
            {
              isShortTermFixAndFlip(values) ?
                <div>
                  <p>Construction Reserve</p>
                  <span>{constructionReserve}</span>
                </div>
                :
                null
            }
            <div>
              <p>Lien</p>
              <span>{lien}</span>
            </div>
            <div>
              <p>As-is Value</p>
              <span>{asIsValue}</span>
            </div>
            {
              isLongTermRental(values) ?
                <div>
                  <p>DSCR</p>
                  <span>{dscr}</span>
                </div>
                :
                null
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const DocumentsBox = ({ preApprovalLetterURL, termSheetURL }) => (
  <div className='documents'>
    <p>Documents</p>
    <div>
      <div>
        <div>
          Pre-approval letter
        </div>
        <span className={"download"}>
          <a href={preApprovalLetterURL}>
            {"Download now"}
          </a>
        </span>
      </div>
      <div>
        <div>
          Term sheet
        </div>
        <span className={"download"}>
          <a href={termSheetURL}>
            {"Download now"}
          </a>
        </span>
      </div>
    </div>
  </div>
)

const ConfirmationBox = ({ isBrokerMode }) => (
  <div className='confirmation-box'>
    <div className='left'>
      <Icon svgFilePath={"./assets/icons/alert-triangle.svg"} />
    </div>
    <div className='right'>
      {
        !isBrokerMode ?
          <p>Within the next 24 hours, we’ll send you an email with instructions and the required documents to finalize your loan</p>
          :
          <p>Within the next 24 hours, we’ll send you an email with instructions and the required documents to finalize this loan</p>
      }
    </div>
  </div>
)

export const InfoBox = () => (
  <div className='confirmation-box'>
    <div className='left'>
      <div className='icon'>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#6E7889" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9.08984 9.00008C9.32495 8.33175 9.789 7.76819 10.3998 7.40921C11.0106 7.05024 11.7287 6.91902 12.427 7.03879C13.1253 7.15857 13.7587 7.52161 14.2149 8.06361C14.6712 8.60561 14.9209 9.2916 14.9198 10.0001C14.9198 12.0001 11.9198 13.0001 11.9198 13.0001" stroke="#6E7889" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 17H12.01" stroke="#6E7889" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div className='right'>
        <p>Enter numbers for the values below and we’ll give you some options. Don’t worry if your numbers aren’t perfect.</p>
    </div>
  </div>
)

const DownArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
    <path d="M11 1L6 6L1 1" stroke="#404859" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

const LoanResumeMobile = (props) => {
  const [areDetailsOpen, setAreDetailsOpen] = useState(false);

  const downloadDocuments = () => {
    const { mobileURL } = props;
    if (mobileURL) {
      window.open(mobileURL, '_blank');
    }
  };

  return (
    <div className='loan-recap loan-recap-mobile'>
      <div><h1 className='tt'>{props.firstName}, your loan application has been submitted!</h1></div>
      <ConfirmationBox />
      {/* <DocumentsBox {...props} /> */}
      <LoanInfoBox {...props} />
      <div className='download-button' onClick={downloadDocuments}>Download pre-approval letter and term sheet</div>
      <div className='resume-container'>
        <FooterContent values={props.values} oneLine isSpan firstPerson isBrokerMode={props.isBrokerMode} />
        <div>
          <p className='view-details' onClick={() => setAreDetailsOpen(!areDetailsOpen)}>
            View Details <span className={!areDetailsOpen ? 'arrow' : 'arrow rotated'}><DownArrow /></span>
          </p>
          {
            areDetailsOpen ?
              <div className='input-container'>
                {props.elements}
              </div>
              :
              null
          }
        </div>
      </div>
    </div>
  )
}