import { gql, useMutation } from '@apollo/client';

const mutation = gql`
  mutation OnboardBorrower($input: OnboardBorrowerInput!) {
    onboardBorrower(onboardBorrowerInput: $input) {
      email
    }
  }
`

export const useBorrowerOnboarding = () => {
  const [execute, { data, fetching, error }] = useMutation(
    mutation,
    { fetchPolicy: 'no-cache' }
  );

  return {
    execute: (data) => execute({ variables: { input: data }}),
    data,
    fetching,
    error
  };
};
