import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from "react-ga4";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloAppProvider } from './utils/apollo'
import { runTests } from './tests';

// runTests();
if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
}

if (!window.google && process.env.REACT_APP_MAPS_API_KEY) {
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&libraries=places&callback=mapsAutoComplete`;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloAppProvider>
      <App />
    </ApolloAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
