import React from 'react';
import './TopBar.scss';
import { Icon } from '../Icon/Icon';

const ProcessingTopBar = (props) => {
  // use your props here

  return (
    <div className="top-bar">
      <div className='logo' onClick={() => { window.location.href = "https://www.constlending.com"; }}>
        <Icon svgFilePath={"./assets/icons/constlending-logo.svg"} />
      </div>
      <div className="internal">
        INTERNAL
      </div>
      <div className='button-blue-outlined' onClick={() => { window.location.href = window.location.origin; }}>
        Create New Borrower Loan
      </div>
    </div>
  );
};

const ConstlendingLogo = () => (
  <div className="constlending-logo">
    <img className="constlending-logo-blue" src={`${process.env.PUBLIC_URL}/assets/icons/constlending-blue.svg`} alt="logo" />
    <img className="constlending-logo-text" src={`${process.env.PUBLIC_URL}/assets/icons/constlending-text.svg`} alt="logo" />
  </div>
)

export default ProcessingTopBar;