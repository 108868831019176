import React from 'react';

import { Icon } from '../../atoms/Icon/Icon';

function LoanOptionsAvailable({ errors }) {
  return (
    <div className='send-loan-form loan-opts'>
      <div className='icon-container'>
        <Icon svgFilePath={"./assets/icons/icon-property-red.svg"} />
      </div>
      <div className='info'>
        <h1 className='title'>
          {"No loan options available"}
        </h1>
        <h3 className='description'>
          Please fix the following or contact us at <a href="mailto:lend@constlending.com">lend@constlending.com</a>
        </h3>
        {
          errors && errors.length > 0 ?
            <ul>
              {
                errors.map((error) => (<li>{error}</li>))
              }
            </ul>
            :
            null
        }
      </div>
    </div>
  );
}

export default LoanOptionsAvailable;