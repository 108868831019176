import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_BE_URL}/graphql`
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
        ...(
          token ?
          {
            'Authorization': `Bearer ${token}`,
            'Apollo-Require-Preflight': 'true'
          }
          :
          {}
        ),
    }
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

export const ApolloAppProvider = ({ children }) => (
  <ApolloProvider client={client}>
    { children }
  </ApolloProvider>
) 