import React from 'react';

export const SelectOption = (props) => {
  const { text, description, onSelect, isSelected, isDisabled, isSpecial } = props;

  const disabledClassNameFactory = (className) => (
    isDisabled === true ? `${className} disabled` : className
  )

  const specialClassNameFactory = (className) => (
    isSpecial === true ? `${className} special` : className
  )

  const selectedClassNameFactory = (className) => (
    isSelected === true ? `${className} selected` : className
  )

  const descriptionClassNameFactory = (className) => (
    Boolean(description) === true ? `${className} description` : className
  )

  const onClick = () => {
    if (!isDisabled) {
      onSelect()
    }
  }

  return (
    <div 
      className={
        descriptionClassNameFactory(
          disabledClassNameFactory(
            selectedClassNameFactory(
              specialClassNameFactory('select-option')
              )
            )
          )
      }
      onClick={onClick}
    >
      <p className={'text'}>{text}</p>
      {
        description
        ?
        <p className={'description'}>{description}</p>
        :
        null
      }
    </div>
  );
}