import React from 'react';
import './TopBar.scss';

import { Icon } from '../../atoms/Icon/Icon';
import { SignInButton } from '../SignInButton/SignInButton';

export const TopBar = (props) => {
  const { } = props;
  return (
    <div className='top-bar'>
      <div className='icon-container' onClick={() => { window.location.href = "https://www.constlending.com"; }}>
        <Icon svgFilePath={"./assets/icons/constlending-logo.svg"} />
      </div>
      <SignInButton />
    </div>
  );
}
