import React, { useRef, useEffect, useState } from 'react';
import { TopBar } from '../TopBar/TopBar';
import { LeftBar } from '../LeftBar/LeftBar';
import ProcessingTopBar from '../../atoms/TopBar/TopBar';
import ProcessingLeftBar from '../../atoms/LeftBar/LeftBar';

import './PageLayout.scss';

export const PageLayout = (props) => {
  const { children, isProcessingTeamMode, isBorrowerMode } = props;

  const className = isProcessingTeamMode || isBorrowerMode ? 'page-layout processing' : 'page-layout'

  return (
    <div className={className}>
      {
        isProcessingTeamMode ?
          <ProcessingTopBar />
          :
          <TopBar />
      }
      <div className='page-main'>
        {
          isProcessingTeamMode || isBorrowerMode ?
            <ProcessingLeftBar />
            :
            <LeftBar />
        }
        <div className='page-content-wrapper'>
          { children }
        </div>
      </div>
    </div>
  );
}

export const PageContent = (props) => {
  const { children, isWide, selectedIndexData, setSelectedIndexData, currentStep } = props;

  const selectedIndex = +selectedIndexData.value

  const componentRef = useRef()

  const [ nextSelectedStep, setNextSelectedStep ] = useState(0)

  useEffect(() => {
    const targetElements = componentRef.current.querySelectorAll('.step-section');

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry, i) => {
        if (entry.isIntersecting) {
          const inScreenIndex = Array.from(targetElements).findIndex(e => e === entry.target)
          setNextSelectedStep(inScreenIndex)
        }
      });
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      {
        root: componentRef.current,
        threshold: 0.8
      }
    );

    targetElements.forEach(element => {
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  /* This is for scroll behavior */
  useEffect(() => {
    if ((nextSelectedStep > currentStep && currentStep < 7) || (nextSelectedStep === 0 && selectedIndex === 10)) {
      setTimeout(() => {
        const scrollOffset = 500 * ( currentStep >= 3 ? currentStep + 1 : currentStep )
        componentRef.current.scroll({ top: scrollOffset, behavior: "smooth"})
      }, 500)
    } else {
      if (nextSelectedStep < selectedIndex) {
        setSelectedIndexData({value: nextSelectedStep, origin: "scroll"})
      } else if ( nextSelectedStep > selectedIndex ) {
        setSelectedIndexData({value: nextSelectedStep, origin: "scroll"})
      }
    }
  }, [nextSelectedStep, currentStep])

  /* This is for button click behavior */
  useEffect(() => {
    if (componentRef.current) {
      if (selectedIndexData.origin !== "scroll") {
        const targetElement = componentRef.current.querySelectorAll('.step-section')[selectedIndexData.value];
        if (!targetElement) return
        componentRef.current.scroll({ top: targetElement.offsetTop, behavior: "smooth"})
      }
    }
  }, [componentRef.current, selectedIndexData])

  const className = isWide ? 'page-content wide' : 'page-content'

  return (
    <div {...{ className }} ref={componentRef}>
      {children}
    </div>
  )
}