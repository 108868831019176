import React from 'react';
import './RoundedBar.scss';

export const RoundedBar = (props) => {
  const { isDisabled = false } = props;
  const className = isDisabled ? 'rounded-bar disabled' : 'rounded-bar';

  return (
    <div {...{ className }}>
    </div>
  );
}
