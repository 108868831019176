import React, { useState } from 'react';
import './BorrowerForm.scss';

import { Icon } from '../../atoms/Icon/Icon';

export const BorrowerForm = (props) => {
  const { onSubmission } = props;

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const OnChage = (fun) => (e) => fun(e.target.value);

  const brokerData = {
    name,
    phoneNumber,
    emailAddress,
    password
  }

  return (
    <div className='borrower-form'>
      <div className='icon-container'>
        <Icon svgFilePath={"./assets/icons/icon-coins.svg"} />
      </div>
      <div className='info'>
        <h1 className='title'>
          Broker with us
        </h1>
        <h3 className='description'>
          Sign up to get access to our streamlined loan dashboard and the best rates for your clients
        </h3>

        <input
          placeholder='Name'
          type='text'
          value={name}
          onChange={OnChage(setName)}
        />
        <input
          placeholder='Phone number'
          type='text'
          value={phoneNumber}
          onChange={OnChage(setPhoneNumber)}
        />
        <input
          placeholder='Email address'
          type='text'
          value={emailAddress}
          onChange={OnChage(setEmailAddress)}
        />
        <input
          placeholder='Password'
          type='password'
          value={password}
          onChange={OnChage(setPassword)}
        />
        <input
          placeholder='Confirm Password'
          type='password'
          value={passwordConfirmation}
          onChange={OnChage(setPasswordConfirmation)}
        />

        <div className='loan-options-button' onClick={() => onSubmission(brokerData)}>
          Create account and continue pricing loan
        </div>
        <div className='sheets-button' onClick={() => {}}>
          Keep me updated on rate sheets
        </div>
        <p className='caption'>
          By submitting this form you agree to be contacted by Constitution Lending
        </p>
      </div>
    </div>
  );
}
