import React from 'react';
import './SignInButton.scss';

export const SignInButton = (props) => {
  const { } = props;
  return null
  
  return (
    <div className='signin-button'>
      <span>Sign in</span>
    </div>
  );
}
