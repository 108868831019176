import { gql, useMutation, useLazyQuery } from '@apollo/client';

const EXCHANGE_OAUTH_TOKEN = gql`
  mutation ExchangeOAuthToken($token: String!) {
    exchangeOAuthToken(token: $token)
  }
`

const GET_USER_LOGGIN = gql`
  query GetUserLoggin {
    GetUserLoggin {
      _id
      name
      lastName
      email
      phone
    }
  }
`;

export const useExchangeOAuthToken = () => {
  const [exchangeOAuthToken, { data, loading, error }] = useMutation(
    EXCHANGE_OAUTH_TOKEN,
    { fetchPolicy: 'no-cache' }
  );

  return {
    exchangeOAuthToken,
    data,
    loading,
    error
  };
};

export const useGetUserLoggin = () => {
  const [getUserLoggin, { data, loading, error }] = useLazyQuery(
    GET_USER_LOGGIN,
    { fetchPolicy: 'no-cache' }
  );

  return {
    getUserLoggin,
    data,
    loading,
    error
  };
};