import React from 'react';
import { RoundedBar } from '../../atoms/RoundedBar/RoundedBar';

import './StepBar.scss';

export const StepBar = (props) => {
  const { selectedStep = 0, isWide, isWider } = props;
  const OPTIONS = ["Background", "Loan Builder"]

  const className = (
    isWide
    ?
    'step-bar wide'
    :
    (
      isWider
      ? 
      'step-bar wider'
      :
      'step-bar'
    )
  )

  return (
    <div className={className}>
      <StepBarContent {...{ options: OPTIONS, selectedStep }} />
    </div>
  );
}

export const StepBarContent = ({ options, selectedStep, setSelectedStep }) => (
  <div className='step-bar-content'>
    {
      options.map(
        (option, i) => {
          const isDisabled = selectedStep !== i
          const className = isDisabled ? 'disabled' : undefined
          return (
            <div {...{ key: `${option}_${i}`, className, onClick: () => setSelectedStep(i) }} >
              {option}
              <RoundedBar isDisabled={isDisabled} />
            </div>
          )
        }
      )
    }
  </div>
)