export const toUSD = (amount, decimalPlaces = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: 2
  });

  return formatter.format(amount);
}

export function updateRentalOptions(isRental) {

  const step = getOnboardingStep(ONBOARDING_STEP_PROPERTY_KIND_KEY);
  const optionTextsToUpdate = ["5-20 unit property"];

  // Object that is replaced is step.options
  step.options = step.options.map(option => {
    if (optionTextsToUpdate.includes(option.text)) {
      if (isRental) {
        return Object.assign({}, option, { isSpecial: true, description: "We can’t automatically price 5-20 unit property loans. But if you finish the remaining questions, one of our loan officers will be in touch within 24 hours" });
      } else {
        return Object.assign({}, option, { isSpecial: false, description: "" });
      }
    }
    return option;
  });
}

export const ONBOARDING_STEP_STATE_KEY = "state"
export const ONBOARDING_STEP_PROPERTY_CONDITION_KEY = "propertyCondition"
export const ONBOARDING_STEP_LOAN_LENGHT_KEY = "loanLength"
export const ONBOARDING_STEP_LOAN_PURPOSE_KEY = "ownsProperty"
export const ONBOARDING_STEP_PROPERTY_KIND_KEY = "propertyKind"
export const ONBOARDING_STEP_CREDIT_SCORE_KEY = "creditScore"
export const ONBOARDING_STEP_INVESTMENT_EXPERIENCE_KEY = "borrowerProperties"

export const ONBOARDING_STEPS = [
  {
    shortKey: ONBOARDING_STEP_STATE_KEY,
    iconPath: "./assets/icons/icon-map.svg",
    text: "Where is the property located?",
    description: "",
    options: [
      { text: 'Alabama', isDisabled: false },
      { text: 'Alaska', isDisabled: true },
      { text: 'Arizona', isDisabled: true },
      { text: 'Arkansas', isDisabled: false },
      { text: 'California', isDisabled: false },
      { text: 'Colorado', isDisabled: false },
      { text: 'Connecticut', isDisabled: false },
      { text: 'Delaware', isDisabled: false },
      { text: 'Florida', isDisabled: false },
      { text: 'Georgia', isDisabled: false },
      { text: 'Hawaii', isDisabled: true },
      { text: 'Idaho', isDisabled: false },
      { text: 'Illinois', isDisabled: false },
      { text: 'Indiana', isDisabled: false },
      { text: 'Iowa', isDisabled: false },
      { text: 'Kansas', isDisabled: false },
      { text: 'Kentucky', isDisabled: false },
      { text: 'Louisiana', isDisabled: false },
      { text: 'Maine', isDisabled: false },
      { text: 'Maryland', isDisabled: false },
      { text: 'Massachusetts', isDisabled: false },
      { text: 'Michigan', isDisabled: false },
      { text: 'Minnesota', isDisabled: false },
      { text: 'Mississippi', isDisabled: false },
      { text: 'Missouri', isDisabled: false },
      { text: 'Montana', isDisabled: true },
      { text: 'Nebraska', isDisabled: false },
      { text: 'Nevada', isDisabled: true },
      { text: 'New Hampshire', isDisabled: false },
      { text: 'New Jersey', isDisabled: false },
      { text: 'New Mexico', isDisabled: false },
      { text: 'New York', isDisabled: false },
      { text: 'North Carolina', isDisabled: false },
      { text: 'North Dakota', isDisabled: true },
      { text: 'Ohio', isDisabled: false },
      { text: 'Oklahoma', isDisabled: true },
      { text: 'Oregon', isDisabled: false },
      { text: 'Pennsylvania', isDisabled: false },
      { text: 'Rhode Island', isDisabled: false },
      { text: 'South Carolina', isDisabled: false },
      { text: 'South Dakota', isDisabled: true },
      { text: 'Tennessee', isDisabled: false },
      { text: 'Texas', isDisabled: false },
      { text: 'Utah', isDisabled: false },
      { text: 'Vermont', isDisabled: false },
      { text: 'Virginia', isDisabled: false },
      { text: 'Washington', isDisabled: false },
      { text: 'West Virginia', isDisabled: true },
      { text: 'Wisconsin', isDisabled: false },
      { text: 'Wyoming', isDisabled: false }
    ],
    selectTitle: "Select a state",
    isSelect: true
  },
  {
    shortKey: ONBOARDING_STEP_PROPERTY_CONDITION_KEY,
    iconPath: "./assets/icons/icon-bulb.svg",
    text: "What condition is the property in?",
    description: "",
    options: [
      {
        text: "It’s stabilized and/or ready to rent",
        description: "Choose this for a bridge or rental loan"
      },
      {
        text: "It needs some repairs and/or construction",
        description: "Only available for fix-and-flip loans"
      }
    ]
  },
  {
    shortKey: ONBOARDING_STEP_LOAN_LENGHT_KEY,
    iconPath: "./assets/icons/icon-calendar.svg",
    text: "What is your ideal loan length?",
    description: "",
    options: [
      {
        text: "Short-term (12 months)",
        description: "Available for bridge or fix-and-flip loans"
      },
      {
        text: "Long-term (30 years)",
        description: "Only available for rental loans",
        isDisabled: false
      }
    ]
  },
  {
    shortKey: ONBOARDING_STEP_LOAN_PURPOSE_KEY,
    iconPath: "./assets/icons/icon-tag.svg",
    text: "Do you currently own this property?",
    description: "",
    options: [
      {
        text: "No, I’m looking to purchase",
        description: ""
      },
      {
        text: "Yes, I want to refinance",
        description: ""
      }
    ]
  },
  {
    shortKey: ONBOARDING_STEP_PROPERTY_KIND_KEY,
    iconPath: "./assets/icons/icon-property.svg",
    text: "What kind of property is it?",
    description: "",
    options: [
      {
        text: "Single family",
        description: ""
      },
      {
        text: "Warrantable condo",
        description: ""
      },
      {
        text: "Non-warrantable condo",
        description: ""
      },
      {
        text: "2-4 unit property",
        description: ""
      },
      {
        text: "5-20 unit property",
        description: "",
      },
      {
        text: "Mixed-use property",
        description: "We can’t automatically price mixed-use property loans. But if you finish the remaining questions, one of our loan officers will be in touch within 24 hours",
        isSpecial: true,
        onLearnMore: () => {}
      },
    ]
  },
  {
    shortKey: ONBOARDING_STEP_CREDIT_SCORE_KEY,
    iconPath: "./assets/icons/icon-bank.svg",
    text: "What’s your credit score?",
    description: "Better credit scores mean better LTVs, but we won’t exclude you just because your score isn’t perfect. Be honest!",
    options: [
      {
        text: "660-679",
        description: "",
      },
      {
        text: "680-699",
        description: "",
      },
      {
        text: "700-719",
        description: ""
      },
      {
        text: "720-739",
        description: ""
      },
      {
        text: "740-759",
        description: ""
      },
      {
        text: "760-779",
        description: ""
      },
      {
        text: "780+",
        description: ""
      },
    ]
  },
  {
    shortKey: ONBOARDING_STEP_INVESTMENT_EXPERIENCE_KEY,
    iconPath: "./assets/icons/icon-contract.svg",
    text: "How many properties have you purchased/sold in the last 24 months?",
    description: "",
    options: [
      {
        text: "None",
        description: ""
      },
      {
        text: "1-2 properties",
        description: ""
      },
      {
        text: "3-5 properties",
        description: ""
      },
      {
        text: "6+ properties",
        description: ""
      }
    ]
  }
]

export function getOnboardingStep(shortKey) {
  const step = ONBOARDING_STEPS.find(step => step.shortKey === shortKey)
  if (!step) {
    throw new Error(`Step with key ${shortKey} not found`);
  }
  return step
}

export function getOnboardingStepTitle(shortKey) {
  return getOnboardingStep(shortKey).text
}