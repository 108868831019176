import React from 'react';
import './LoanOption.scss';

import { toUSD } from '../../../utils/constants';
import { InfoTooltip } from '../../molecules/LoanBuilder/Tooltip';

export const LoanOption = (props) => {
  const {
    rate,
    loanTerm,
    monthlyPayment,
    originationPoints,
    caption,
    totalLoanAmount = 0,
    ltv,
    isResume = false,
    onSelect,
    programName,
    bondPrice
  } = props;

  return (
    <div className='loan-option' onClick={onSelect}>
      <div className='loan-option-values'>
        {
          isResume ?
            <div>
              <span>LTV</span>
              <span>{Number.isInteger(ltv * 100) ? (ltv * 100) : (ltv * 100).toFixed(1)}%</span>
            </div>
            :
            <div className='rate'>
              <span>Rate</span>
              <span>{rate}%</span>
            </div>
        }
        <div>
          <span>Loan Term</span>
          <span>{loanTerm}</span>
        </div>
        {
          isResume ?
            <div>
              <span>Rate</span>
              <span>{rate}%</span>
            </div>
            :
            null
        }
        <div>
          <span>Monthly Payment <InfoTooltip title={"This doesn’t include taxes and insurance"} variant={"gray"}/></span>
          <span>{toUSD(monthlyPayment)}</span>
        </div>
        {
          !isResume ?
            <React.Fragment>
              <div>
                <span>Buydown <InfoTooltip title={"Pay a percentage of the loan amount upfront to decrease the interest rate over the life of the loan"} variant={"gray"}/></span>
                <span>{Number(originationPoints).toFixed(2)}%</span>
              </div>
              <div className='choose'>
                <div>
                  Choose
                </div>
              </div>
            </React.Fragment>
            :
            <div>
              <span>Total Loan Amount</span>
              <span>{toUSD(totalLoanAmount, 0)}</span>
            </div>
        }
      </div>
      {
        caption
        ?
        <div className='caption'>
          {
            process.env.REACT_APP_NODE_ENV !== "production" && programName && originationPoints === 0 ?
              <span>{`${caption} - ${programName} - ${bondPrice}`}</span>
            :
            <span>{caption}</span>
          }
        </div>
        :
        null
      }
    </div>
  );
}
