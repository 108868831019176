import debounce from "lodash.debounce"
import { ONBOARDING_STEPS, ONBOARDING_STEP_CREDIT_SCORE_KEY } from "./constants";

const PRICER_URL = "https://sbo.constlending.com"
// const PRICER_URL = "http://localhost:5000"

const DEBOUNCE_DELAY_MS = 500

const controllers = []
const buydownValues = [0];

export const loan_computation = debounce(async (values, cb) => {
  console.log('Doing computation', { values })

  if (isShortTermBridge(values)) {
    function mapInputs(oldInputs, buydown) {
      const investmentExperience = oldInputs["How many properties have you purchased/sold in the last 24 months?"].split(" ")[0]
      const newInputs = {
        "borrower_credit_score": oldInputs["What’s your credit score?"],
        "borrower_24_months_experience": investmentExperience === "None" ? "0" : investmentExperience,
        "as_is_value_purchase_price": oldInputs.price,
        "if_refi_payoff_amount": oldInputs.currentLoanBalance || 0,
        "buydown_in_pts": buydown,
        ...(
          oldInputs.loanBalance ?
            {
              "initial_loan_amount": oldInputs.loanBalance 
            }
            :
            {}
        )
      }
  
      return newInputs
    }
  
    function mapOutputs(outputs) {
      const oldOutputs = {
        "rate": outputs.rate,
        "advance_amount_max": outputs.advance_amount_max
      }
  
      return {...oldOutputs, ...outputs}
    }
  
    const isOptimize = !mapInputs(values).initial_loan_amount
    const pricerUrl = `${PRICER_URL}/pricer/bridge${isOptimize ? "?multiple=True&optimize=True" : "?multiple=True"}`
  
    const fetchPromisesGetter = buydownValues.map(buydownValue => {
      return () => {
        const controller = new AbortController();
        const signal = controller.signal;
        controllers.push(controller);
    
        return fetch(pricerUrl, {
          method: 'post',
          body: JSON.stringify(mapInputs(values, buydownValue)),
          headers: {
            'Content-Type': 'application/json'
          },
          signal
        })
        .then(res => res.json())
        .then(data => mapOutputs(data.data))
        .finally(() => {
          const index = controllers.indexOf(controller);
          if (index !== -1) {
            controllers.splice(index, 1);
          }
        })
      }
    });
  
    return new Promise(() => {
      controllers.forEach(controller => controller.abort());
      Promise
        .all(fetchPromisesGetter.map((f) => f()))
        .then((data) => cb(data))
        .catch(() => cb([null]))
    });
  } else {
    function mapInputs(oldInputs, buydown) {
      const investmentExperience = oldInputs["How many properties have you purchased/sold in the last 24 months?"].split(" ")[0]
      const newInputs = {
        "borrower_credit_score": oldInputs["What’s your credit score?"],
        "borrower_24_months_experience": investmentExperience === "None" ? "0" : investmentExperience,
        "as_is_value_purchase_price": oldInputs.price,
        "if_refi_payoff_amount": oldInputs.currentLoanBalance || 0,
        "rehab_budget": oldInputs.constructionBudget,
        "arv": oldInputs.afterRepairValue,
        "buydown_in_pts": buydown,
        ...(
          oldInputs.loanBalance ?
            {
              "total_loan_amount": oldInputs.loanBalance
            }
            :
            {}
        )
      }
  
      return newInputs
    }
  
    function mapOutputs(outputs) {
      const oldOutputs = {
        "rate": outputs.rate,
        "advance_amount_max": outputs.advance_amount_max,
        "construction_reserve_max": outputs.construction_reserve
      }
  
      return {...oldOutputs, ...outputs}
    }
  
    const isOptimize = !mapInputs(values).total_loan_amount
    const pricerUrl = `${PRICER_URL}/pricer/fix_and_flip${isOptimize ? "?multiple=True&optimize=True" : "?multiple=True"}`

    const fetchPromisesGetter = buydownValues.map(buydownValue => {
      return () => {
        const controller = new AbortController();
        const signal = controller.signal;
        controllers.push(controller);
    
        return fetch(pricerUrl, {
          method: 'post',
          body: JSON.stringify(mapInputs(values, buydownValue)),
          headers: {
            'Content-Type': 'application/json'
          },
          signal
        })
        .then(res => res.json())
        .then(data => mapOutputs(data.data))
        .finally(() => {
          const index = controllers.indexOf(controller);
          if (index !== -1) {
            controllers.splice(index, 1);
          }
        })
      }
    });
  
    return new Promise(() => {
      controllers.forEach(controller => controller.abort());
      Promise
        .all(fetchPromisesGetter.map((f) => f()))
        .then((data) => cb(data))
        .catch(() => cb([null]))
    });
  }
}, DEBOUNCE_DELAY_MS)

export const rental_loan_computation = debounce(async (values, cb) => {
  function mapInputs(oldInputs, buydown) {
    const newInputs = {
      property_type: oldInputs.propertyType,
      loan_purpose: isRefinance(oldInputs) ? "Refinance" : "Purchase",
      fico: oldInputs["What’s your credit score?"],
      buydown,
      price: oldInputs.price,
      payoff: oldInputs.currentLoanBalance || 0,
      monthly_market_rent: oldInputs.monthlyRent,
      annual_property_taxes: oldInputs.annualTaxes,
      annual_insurance: oldInputs.annualInsurance,
      annual_hoa: oldInputs.annualHoa,
      ...(
        oldInputs.loanBalance ?
          {
            loan_balance: oldInputs.loanBalance 
          }
          :
          {}
      )
    }

    return newInputs
  }

  function mapOutputs(outputs) {
    const oldOutputs = {
      "buydown_fee_usd": 0,
      "can_be_thirty_year_fixed_loan": true,
      "loan_amount": outputs.loan_balance,
      "ltv": outputs.ltv,
      "monthly_payment": outputs.monthly_payment,
      "total_rate": outputs.rate,
      "dscr": outputs.dscr,
      "max_ltv": outputs.max_ltv,
      "cashout_amount": outputs.cashout_amount,
      "bond_price": outputs.bond_price,
      "is_extended": outputs.is_extended
    }

    return {...oldOutputs}
  }
  
  const isOptimize = !mapInputs(values).loan_balance
  const pricerUrl = `${PRICER_URL}/pricer/rental${isOptimize ? "?multiple=True&optimize=True" : "?multiple=True"}`

  const fetchPromisesGetter = buydownValues.map(buydownValue => {
    return () => {
      const controller = new AbortController();
      const signal = controller.signal;
      controllers.push(controller);
  
      return fetch(pricerUrl, {
        method: 'post',
        body: JSON.stringify(mapInputs(values, buydownValue)),
        headers: {
          'Content-Type': 'application/json'
        },
        signal
      })
      .then(res => res.json())
      .then(data => data.data.map(mapOutputs))
      .finally(() => {
        const index = controllers.indexOf(controller);
        if (index !== -1) {
          controllers.splice(index, 1);
        }
      })
    }
  });

  return new Promise(() => {
    controllers.forEach(controller => controller.abort());
    Promise
      .all(fetchPromisesGetter.map((f) => f()))
      .then((data) => cb(data))
      .catch(() => cb([null]))
  });
}, DEBOUNCE_DELAY_MS)

/* Input mapping */
const CREDIT_SCORE = {
  ["<660"]: "640-659",
  ["660-679"]: "660-679",
  ["680-699"]: "680-699",
  ["700-719"]: "700-719",
  ["720+"]: "720"
}

const STATES = {
  ["Alaska"]: "AK",
  ["Alabama"]: "AL",
  ["Arizona"]: "AZ",
  ["Arkansas"]: "AR",
  ["California"]: "CA",
  ["Colorado"]: "CO",
  ["Connecticut"]: "CT",
  ["Delaware"]: "DE",
  ["Florida"]: "FL",
  ["Georgia"]: "GA",
  ["Hawaii"]: "HI",
  ["Idaho"]: "ID",
  ["Illinois"]: "IL",
  ["Indiana"]: "IN",
  ["Iowa"]: "IA",
  ["Kansas"]: "KS",
  ["Kentucky"]: "KY",
  ["Louisiana"]: "LA",
  ["Maine"]: "ME",
  ["Maryland"]: "MD",
  ["Massachusetts"]: "MA",
  ["Michigan"]: "MI",
  ["Minnesota"]: "MN",
  ["Mississippi"]: "MS",
  ["Missouri"]: "MO",
  ["Montana"]: "MT",
  ["Nebraska"]: "NE",
  ["Nevada"]: "NV",
  ["New Hampshire"]: "NH",
  ["New Jersey"]: "NJ",
  ["New Mexico"]: "NM",
  ["New York"]: "NY",
  ["North Carolina"]: "NC",
  ["North Dakota"]: "ND",
  ["Ohio"]: "OH",
  ["Oklahoma"]: "OK",
  ["Oregon"]: "OR",
  ["Pennsylvania"]: "PA",
  ["Rhode Island"]: "RI",
  ["South Carolina"]: "SC",
  ["South Dakota"]: "SD",
  ["Tennessee"]: "TN",
  ["Texas"]: "TX",
  ["Utah"]: "UT",
  ["Vermont"]: "VT",
  ["Virginia"]: "VA",
  ["Washington"]: "WA",
  ["West Virginia"]: "WV",
  ["Wisconsin"]: "WI",
  ["Wyoming"]: "WY"
}

const PROPERTY_KIND = {
  ["Single family"]: "Single Family/Condo",
  ["Warrantable condo"]: "None of these",
  ["Non-warrantable condo"]: "None of these",
  ["2-4 unit property"]: "2-4 Unit",
  ["5-20 unit property"]: "5+ Multifamily",
}

const PROPERTY_TYPE = {
  ["Single family"]: "Single Family",
  ["Warrantable condo"]: "Warrantable Condo",
  ["Non-warrantable condo"]: "Non-Warrantable Condo",
  ["2-4 unit property"]: "2-4 Unit",
  ["5-20 unit property"]: "5-20 Unit",
  ["Mixed-use property"]: "Mixed Use"
}

const COMPLETED_PROJECTS = {
  ["None"]: 0,
  ["1-3 properties"]: 2,
  ["3-6 properties"]: 4,
  ["6+ properties"]: 7,
}

/**
 * This function converts the values that were obtained from the application
 * questionary to the old object shape that was used in the original pricer
 * 
 * @param {object} inputs The result of the questionary
 */
export const mapInput = (inputs) => {
  let out = {...inputs}
  /* Mapping values */
  out['creditScore'] = inputs['What’s your credit score?']
  out['creditScore'] = CREDIT_SCORE[out['creditScore']]

  out['state'] = inputs['Where is the property located?']
  out['state'] = STATES[out['state']]

  out['propertyKind'] = inputs['What kind of property is it?']
  out['propertyKind'] = PROPERTY_KIND[out['propertyKind']]

  out['completedProjects'] = inputs['How many properties have you purchased/sold in the last 24 months?']
  out['completedProjects'] = COMPLETED_PROJECTS[out['completedProjects']]

  out['asIsValue'] = inputs['price']

  /* Adding missing values */
  if (isRefinance(inputs)) {
    out['refinance'] = 'yes'
    out['price'] = inputs['price']
    out['asIsValue'] = inputs['price']
  } else {
    out['refinance'] = 'no'
  }
  
  if (isShortTermBridge(inputs)) {
    out = {...out, "propertyRehab": "No" }
  }

  if (isShortTermFixAndFlip(inputs)) {
    out['constructionBudget'] = inputs['constructionBudget']
    out['afterRepairValue'] = inputs['afterRepairValue']

    out = {...out, "propertyRehab": "Yes", "customLTC": "Max" }
  }

  if (isLongTermRental(inputs)) {
    out["purchaseRefinance"] = out["refinance"]

    out = {
      ...out,
      "propertyState": out['state'],
      "propertyType": PROPERTY_TYPE[inputs['What kind of property is it?']]
    }
  }
  
  return out
}

/* Utils */
export const isShortTermBridge = (values) => (
  values["What condition is the property in?"] === "It’s stabilized and/or ready to rent"
  &&
  values["What is your ideal loan length?"] === "Short-term (12 months)"
)

export const isShortTermFixAndFlip = (values) => (
  values["What condition is the property in?"] === "It needs some repairs and/or construction"
  &&
  values["What is your ideal loan length?"] === "Short-term (12 months)"
)

export const isLongTermRental = (values) =>  (
  values["What condition is the property in?"] === "It’s stabilized and/or ready to rent"
  &&
  values["What is your ideal loan length?"] === "Long-term (30 years)"
)

export const isRefinance = (values) => (values["Do you currently own this property?"] === "Yes, I want to refinance")

export const isSingleHome = (values) => (values["What kind of property is it?"] === "Single family")

export const isTwoToFourUnit = (values) => (values["What kind of property is it?"] === "2-4 unit property")

export const isFiveToTwentyUnit = (values) => (values["What kind of property is it?"] === "5-20 unit property")

export const isMixedUseProperty = (values) => (values["What kind of property is it?"] === "Mixed-use property")

export const cannotBePriced = (values) => (
  (isLongTermRental(values) && isFiveToTwentyUnit(values))
  ||
  (isMixedUseProperty(values))
  // ||
  // (isLongTermRental(values) && (ONBOARDING_STEPS.find(o => o.shortKey === ONBOARDING_STEP_CREDIT_SCORE_KEY).options.findIndex((option) => option.text === values[ONBOARDING_STEPS.find(o => o.shortKey === ONBOARDING_STEP_CREDIT_SCORE_KEY).text]) < 2))
)