import React, { useRef, useEffect, useState } from 'react';
import './SendLoanForm.scss';

import { Icon } from '../../atoms/Icon/Icon';
import { SendLoanFormSubmitted } from '../SendLoanFormSubmitted/SendLoanFormSubmitted';

export const SendLoanForm = (props) => {
  const { isSelected, setBottomValue } = props;

  const [formSubmitted, setFormSubmitted] = useState(false)

  const onSubmission = async () => {
    await new Promise(res => setTimeout(res, 2000))
    console.log('Form submitted!')
    setFormSubmitted(true)
  }

  return (
    <div {...{ isSelected, setBottomValue }} className='step-section form'>
      {
        !formSubmitted
          ?
          <SendLoanFormContent onSubmission={onSubmission} />
          :
          <SendLoanFormSubmitted />
      }
    </div>
  );
}

const StepSectionWrapper = (props) => {
  const { isSelected, setBottomValue, children } = props;

  const stepRef = useRef()

  useEffect(() => {
    if (stepRef.current && isSelected) {
      setBottomValue(stepRef.current.offsetTop)
    }
  }, [isSelected, stepRef.current])

  return (
    <div className='step-section' ref={stepRef}>
      {children}
    </div>
  );
}

export const SendLoanFormContent = (props) => {
  const { onSubmission, title } = props;

  return (
    <div className='send-loan-form'>
      <div className='icon-container'>
        <Icon svgFilePath={"./assets/icons/icon-coins-blue.svg"} />
      </div>
      <div className='info'>
        <h1 className='title'>
          {title ?? "Where can we send loan options?"}
        </h1>
        <h3 className='description'>
          Enter your contact information below and one of our loan officers will reach out with custom loan options
        </h3>

        <input placeholder='Name' type='text' />
        <input placeholder='Phone number' type='text' />
        <input placeholder='Email address' type='text' />

        <div className='loan-options-button' onClick={() => onSubmission()}>
          Contact me with loan options
        </div>
        <p className='caption'>
          By submitting this form you agree to be contacted by Constitution Lending
        </p>
      </div>
    </div>
  );
}