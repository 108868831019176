import React, { useEffect } from 'react';
import './WelcomePage.scss';

import { Icon } from '../../atoms/Icon/Icon';
import { StepBarContent } from '../StepBar/StepBar';
import { trackEvent, SectionLoaded } from '../../../utils/mixpanel';

export const WelcomePage = (props) => {
  const { onContinue, onBrokerContinue } = props;

  useEffect(() => {
    trackEvent(SectionLoaded('Welcome Page'))
  }, [])
  
  return (
    <div className='welcome-page'>
      <div className='left-column'>
        <div className='content'>
          <div className='welcome'>
            <Icon svgFilePath={"./assets/icons/icon-coins.svg"} />
            <h1>Welcome!</h1>
            <p>We’re going to ask you a few questions to get you the most accurate term sheet. If you don’t know something, just estimate.</p>
            <p>Please note we only do loans for investment properties.</p>
          </div>

          <div className='rate' onClick={onContinue}>
            Give me my rate!
          </div>

          <div className='broker' onClick={onBrokerContinue}>
            <span>
              {"I’m a broker -->"}
            </span>
          </div>

          {/* <p className='sign'>
            Already have an account? <span>Sign in</span>
          </p> */}
        </div>
      </div>
      <div className='right-column'>
        <p className='testimonial'>
          "You are my angel!"
        </p>
        <p className='testimonial description'>
          "Dealing with multiple lenders is SO exhausting and wondering if I'd get financing made me apprehensive about chasing deals. Thanks to you, I can just focus on buying properties!"
        </p>
        <p className='caption'>
          <b>— Chandra M,</b> Real estate investor
        </p>

        <div className='welcome-image'>
          <img width={"100%"} src={`${process.env.PUBLIC_URL}/assets/images/welcome-image.png`} alt="description" />
        </div>
      </div>
    </div>
  );
}