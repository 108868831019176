import React from 'react';
import './LeftBar.scss';

export const LeftBar = (props) => {
  const { } = props;
  return (
    <div className='left-bar'>
    </div>
  );
}
