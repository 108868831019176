import { gql, useQuery } from '@apollo/client';

const query = gql`
  query GetBorrowers {
    getBorrowers {
      name
      lastName
      email
      phone
    }
  }
`

export const useGetBorrowers = () => {
  const { data, loading, error } = useQuery(
    query,
    { fetchPolicy: 'no-cache' }
  );

  return {
    data,
    loading,
    error
  };
};